import {
    makeResponsive,
    placeTitle,
    placeImage,
    placeInput,
    placeSlider,
    hoverMe,
    placeRec,
    hiddenPt,
    fixedPt,
    clearInputFields,
    dragMe,
    placeArrow,
    placeGravity,
    placeText,
    placeLine,
    placePoint,
    placeGlider,
    placeRuler,
    placeLeftText,
    placeSliderSwitch,
    placeRightText,
    placeShuffle,
    placeTest,
    placeMiddleText,
    placeBoldText,
    placeLogo,
    createSpace,
    placeWhite,
    placeBCircles,
    placeCircles,
    placeChecks,
    placeCross,
    placeExclaim,
    placeFingers,
    writeHTMLText,
    placeRedo,
    placeStartOver
} from '../Utils';
const Boxes = {
    box1: function () {
      ///////////////////////////////////GLOBAL SETTINGS//////////////////////
      	JXG.Options.board.minimizeReflow = 'none';
        JXG.Options.point.showInfoBox=false;
        JXG.Options.point.highlight=false;
        JXG.Options.image.highlight=false;
        JXG.Options.line.highlight=false;
        JXG.Options.text.highlight=false;
        JXG.Options.text.fixed=true;
        JXG.Options.curve.highlight=false;
        JXG.Options.text.cssDefaultStyle='fontFamily:Oswald;'
        //////////////////////////////////LOCAL SETTINGS//////////////////////////////////
        var graph =createSpace(-10,10,-10,10);
        makeResponsive(graph);
        placeLogo(graph);
        graph.suspendUpdate();
        graph.options.layer['image']=26;
        graph.options.layer['text']=5;
        graph.options.layer['html']=2;
        graph.options.layer['line']=2;
        graph.options.layer['point']=10;
        graph.options.layer['circle']=1;
        graph.options.image.highlight=false;
        /*********************GRAPH DIMENSIONS*******************/
        const boundingBox = graph.attr.boundingbox;
        const positionX = (boundingBox[2]+boundingBox[0])/2;
        const positionY = (boundingBox[1]+boundingBox[3])/2;
        const height = (boundingBox[1]-boundingBox[3])/2;
        //////////////////////////////////////////////////////////////////////////////
      //Place Title
        placeTitle(graph, 'Venn Diagram', '(Sets, their Union & Intersection)');
      //
        placeBoldText(graph, 0, 6.5, 'Select regions that belong to:')
      /////////////////////////////////////////////////////////////////

      /***********************SCORING *******************/
      var yourScore =0; var yourMissed =0; var yourWrong =0; var yourTotal=0;
      var scoreCard = writeHTMLText(graph, positionX, positionY+1, function(){return 'Your Score ( &check; ): '+ yourScore+'/5'});
      var missedCard = writeHTMLText(graph, positionX, positionY, function(){return 'Missed Questions ( ! ): '+ yourMissed+'/5'});
      var wrongCard = writeHTMLText(graph, positionX, positionY-1, function(){return 'Wrong Answers ( x ): '+ yourWrong+'/5'});
      scoreCard.setAttribute({visible:false});
      missedCard.setAttribute({visible:false});
      wrongCard.setAttribute({visible:false});
      ///////////////////////////GRAPHICS MODULES//////////////////
      var bck =placeWhite(graph);
      var show =placeBCircles(graph);
      var hide=placeCircles(graph);
      var check = placeChecks(graph);
      var cross = placeCross(graph);
      var exclaim = placeExclaim(graph);
      var pointers = placeFingers(graph);
      hide[0].setAttribute({visible:false});
      /***************************BUTTON MODULES******************/
      var test =placeTest(graph,'left');
      hoverMe(graph, test, 'Check Your Answer', -20, 0);
      /**/
      var shuffle = placeShuffle(graph);
      hoverMe(graph, shuffle, 'Next Question', 0, 0);
      /**/
      var redo = placeStartOver(graph);
      redo.setAttribute({visible:false});
      hoverMe(graph, redo, 'Start Over', 0, 0);
      /***************************************/
      var txt1 = graph.create('text', [4.1, 6.5, ' A ⋃ B'],{display:'internal',visible: true, fixed: true, anchorX: 'left', anchorY: 'middle', CssStyle:'fontFamily:Oswald;face:bold',fontSize:function(){return Math.round(22*graph.canvasWidth/800.)}},);
      //Correct or erase function whiteEverithing ...     !!!!
      //Variables
      var tx = -1;
      var ty = -10;
      var posx_ed1 = -10;
      var posy_ed1 = 9;
      var sizeOk = 1.0;
      var size_ed = 0.8;
      var size_tap = 1;
      var max_pages = 5;
      var current_page = 0;
      var pages = [];

      var right_answer = 0;

      let i=0;
      var maxSteps = 5;

      var radius = 2.5;
      var ax = -2;
      var ay = -1.5;
      var bx = 2;
      var by = -1.5;
      var cx = 0;
      var cy = 1.5;

      var wpol = 5;
      var hpol = 5;

      var color1 = '#ffffff';
      var color2 = '#FF3B3B ';
      var color4 = '#5B43FF ';

      var current_colorA = color1;
      var current_colorB = color1;
      var current_colorC = color1;

      var current_colorAB = color1;
      var current_colorAC = color1;
      var current_colorBC = color1;

      var current_colorABC = color1;

      var current_colorUniverse = color1;


      JXG.joinCurves = function(board, parents, attributes) {
          var curves = parents,
              attr = JXG.copyAttributes(attributes, board.options, 'curve'),
              c = board.create('curve', [[0], [0]], attr);

          c.updateDataArray = function() {
              var i = curves.length;

              // The paths have to be connected
              this.dataX = [];
              this.dataY = [];
              for (i = 0; i < curves.length; i++) {
                  if (i < curves.length - 1) {
                      this.dataX = this.dataX.concat(curves[i].dataX.slice(0,-1));
                      this.dataY = this.dataY.concat(curves[i].dataY.slice(0,-1));
                  } else {
                      this.dataX = this.dataX.concat(curves[i].dataX);
                      this.dataY = this.dataY.concat(curves[i].dataY);
                  }
              }

              if (this.dataX.length<4) {
                  this.bezierDegree = 1;
              } else {
                  this.bezierDegree = curves[0].bezierDegree;
              }
          };
          c.prepareUpdate().update().updateVisibility().updateRenderer();
          return c;
      };

      //Tetxs
      var txt_A = graph.create('text', [ax, ay, 'A'],{display:'internal',visible: true, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(18*graph.canvasWidth/500.)}},);
      var txt_B = graph.create('text', [bx, by, 'B'],{display:'internal',visible: true, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(18*graph.canvasWidth/500.)}},);
      var txt_C = graph.create('text', [cx, cy, 'C'],{display:'internal',visible: true, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(18*graph.canvasWidth/500.)}},);
      var txt_U = graph.create('text', [-4, 4, 'U'],{display:'internal', visible: true, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(18*graph.canvasWidth/500.)}},);


      //Polygon
      //var universe = graph.create('polygon', [[-wpol, -hpol], [wpol, -hpol], [wpol, hpol], [-wpol, hpol]], {visible: true, vertices:{visible:false, fixed:true}, borders:{visible:true, strokeColor: 'black', strokeWidth: 6, highlightStrokeColor: 'black'}, fillColor: color1, highlightfillColor: color1, highlightfillOpacity: 1.0, fillOpacity:1.0 , layer: 2});
      //var universe = placeCircle(graph, [0,0], [5, 0], 'black');
      var universe =graph.create('circle', [[0,0], [7, 0]], {fillOpacity:0.5, dash:1, strokeColor: 'black', strokeWidth: 1, fillColor:color1, layer: 2, highlightStrokeColor: 'black', highlightfillColor: color2})
      // Create two arcs (or circles)
      var p1 = graph.create('point', [ax, ay], {face:'o' , name:'p1', strokeColor: 'black', fillColor: '#5B43FF',  size:3, withLabel:true, fixed:true, visible:false, label:{fontSize:function(){return 18*graph.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});
      var p2 = graph.create('point', [ax + radius, ay], {face:'o' , name:'p2', strokeColor: 'black', fillColor: 'red',  size:3, withLabel:true, fixed:true, visible:false, label:{fontSize:function(){return 18*graph.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});
      var a1 = graph.create('circle', [p1,p2], {fillOpacity:0.75, strokeColor: 'black', dash:1, strokeWidth: 1, fillColor: color1, layer: 2, highlightStrokeColor: 'black', highlightfillColor: color1});

      var p3 = graph.create('point', [bx, by], {face:'o' , name:'p3', strokeColor: 'black', fillColor: '#5B43FF',  size:3, withLabel:true, fixed:true, visible:false, label:{fontSize:function(){return 18*graph.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});
      var p4 = graph.create('point', [bx + radius, by], {face:'o' , name:'p4', strokeColor: 'black', fillColor: 'red',  size:3, withLabel:true, fixed:true, visible:false, label:{fontSize:function(){return 18*graph.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});
      var a2 = graph.create('circle', [p3,p4], {fillOpacity:0.75, strokeColor: 'black', dash:1, strokeWidth: 1, fillColor: color1, layer: 2, highlightStrokeColor: 'black', highlightfillColor: color1});

      var p5 = graph.create('point', [cx, cy], {face:'o' , name:'p5', strokeColor: 'black', fillColor: '#5B43FF',  size:3, withLabel:true, fixed:true, visible:false, label:{fontSize:function(){return 18*graph.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});
      var p6 = graph.create('point', [cx + radius, cy], {face:'o' , name:'p6', strokeColor: 'black', fillColor: 'red',  size:3, withLabel:true, fixed:true, visible:false, label:{fontSize:function(){return 18*graph.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});
      var a3 = graph.create('circle', [p5,p6], {fillOpacity:0.75, strokeColor: 'black', dash:1, strokeWidth:1, fillColor: color1, layer: 2, highlightStrokeColor: 'black', highlightfillColor: color1});


      // Create two intersection points
      var i1 = graph.create('intersection', [a1,a2,1], {visible:false, name:'i1', label:{fontSize:function(){return 18*graph.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});
      var i2 = graph.create('intersection', [a1,a3,0], {visible:false, name:'i2', label:{fontSize:function(){return 18*graph.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});
      var i3 = graph.create('intersection', [a2,a3,1], {visible:false, name:'i3', label:{fontSize:function(){return 18*graph.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});
      var i4 = graph.create('intersection', [a1,a2,0], {visible:false, name:'i4', label:{fontSize:function(){return 18*graph.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});
      var i5 = graph.create('intersection', [a1,a3,1], {visible:false, name:'i5', label:{fontSize:function(){return 18*graph.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});
      var i6 = graph.create('intersection', [a2,a3,0], {visible:false, name:'i6', label:{fontSize:function(){return 18*graph.canvasHeight/800}, cssStyle:'fontFamily:Oswald'}});


      // Create three arcs surrounding the intersection area
      var c1 = graph.create('arc', [p1, i2, i1], {visible:false, strokeColor: 'black', strokeWidth: 0});
      var c2 = graph.create('arc', [p3, i1, i3], {visible:false, strokeColor: 'black', strokeWidth: 0});
      var c3 = graph.create('arc', [p5, i3, i2], {visible:false, strokeColor: 'black', strokeWidth: 0});

      // Create three arcs surrounding the intersection area AC
      var c4 = graph.create('arc', [p1, i2, i5], {visible:false, strokeColor: 'black', strokeWidth: 0});
      var c5 = graph.create('arc', [p5, i5, i2], {visible:false, strokeColor: 'black', strokeWidth: 0})

      // Create three arcs surrounding the intersection area BC
      var c6 = graph.create('arc', [p3, i6, i3], {visible:false, strokeColor: 'black', strokeWidth: 0});
      var c7 = graph.create('arc', [p5, i3, i6], {visible:false, strokeColor: 'black', strokeWidth: 0})

      // Create three arcs surrounding the intersection area BC
      var c8 = graph.create('arc', [p1, i4, i1], {visible:false, strokeColor: 'black', strokeWidth: 0});
      var c9 = graph.create('arc', [p3, i1, i4], {visible:false, strokeColor: 'black', strokeWidth: 0})


      // Join the three arcs and fill the area.

      var AC = JXG.joinCurves(graph, [c4, c5],
          {fillColor:color1, fillOpacity:0.5, strokeColor: 'black', strokeWidth:1, dash:1
      });

      var BC = JXG.joinCurves(graph, [c7, c6],
          {fillColor:color1, fillOpacity:0.75, strokeColor: 'black', strokeWidth:1, dash:1
      });


      var AB = JXG.joinCurves(graph, [c9, c8],
          {fillColor:color1, fillOpacity:0.75, strokeColor: 'black', strokeWidth:1, dash:1
      });

      var ABC = JXG.joinCurves(graph, [c1, c2, c3],
          {fillColor:color1, fillOpacity:0.75, strokeColor: 'black', strokeWidth:1, dash:1
      });


      //Functions
      var getMouseCoords = function(e, i) {
          var cPos = graph.getCoordsTopLeftCorner(e, i),
              absPos = JXG.getPosition(e, i),
              dx = absPos[0]-cPos[0],
              dy = absPos[1]-cPos[1];

          return new JXG.Coords(JXG.COORDS_BY_SCREEN, [dx, dy], graph);
      }

      var colorThings = function(e) {
          var canCreate = true, i, coords, el;

          if (e[JXG.touchProperty]) {
              // index of the finger that is used to extract the coordinates
              i = 0;
          }
          coords = getMouseCoords(e, i);

          for (el in graph.objects) {
              if(JXG.isPoint(graph.objects[el]) && graph.objects[el].hasPoint(coords.scrCoords[1], coords.scrCoords[2])) {
                  canCreate = false;
                  break;
              }
          }

          if (canCreate) {

              var posx = coords.usrCoords[1];
              var posy = coords.usrCoords[2];

              insideA(posx, posy);
              insideB(posx, posy);
              insideC(posx, posy);

              colorA(posx, posy);
              colorB(posx, posy);
              colorC(posx, posy);

              colorAB(posx, posy);
              colorAC(posx, posy);
              colorBC(posx, posy);
              colorABC(posx, posy);

              colorUniverse(posx, posy)

          }
      }


      function insideA(posx, posy){
          if( Math.pow( (posx - ax) ,2) + Math.pow( (posy - ay) ,2) <= radius*radius ){
              return true;
          }
          else{
              return false;
          }
      }

      function insideB(posx, posy){
          if( Math.pow( (posx - bx) ,2) + Math.pow( (posy - by) ,2) <= radius*radius ){
              return true;
          }
          else{
              return false;
          }
      }

      function insideC(posx, posy){
          if( Math.pow( (posx - cx) ,2) + Math.pow( (posy - cy) ,2) <= radius*radius ){
              return true;
          }
          else{
              return false;
          }
      }

      function colorA(posx, posy){
          if(insideA(posx, posy) == true && insideB(posx, posy) == false && insideC(posx, posy) == false){
              if(current_colorA == color2){
                  a1.setAttribute({fillColor:color1, highlightfillColor: color1});
                  current_colorA = color1;
              }
              else{
                  a1.setAttribute({fillColor:color2, highlightfillColor: color2});
                  current_colorA = color2;
              }
          }

      }

      function colorB(posx, posy){
          if(insideB(posx, posy) == true && insideA(posx, posy) == false && insideC(posx, posy) == false){
              if(current_colorB == color2){
                  a2.setAttribute({fillColor:color1, highlightfillColor: color1});
                  current_colorB = color1;
              }
              else{
                  a2.setAttribute({fillColor:color2, highlightfillColor: color2});
                  current_colorB = color2;
              }
          }
      }

      function colorC(posx, posy){
          if(insideC(posx, posy) == true && insideA(posx, posy) == false && insideB(posx, posy) == false){
              if(current_colorC == color2){
                  a3.setAttribute({fillColor:color1, highlightfillColor: color1});
                  current_colorC = color1;
              }
              else{
                  a3.setAttribute({fillColor:color2, highlightfillColor: color2});
                  current_colorC = color2;
              }
          }
      }

      function colorAB(posx, posy){
          if(insideA(posx, posy) == true && insideB(posx, posy) == true && insideC(posx, posy) == false){
              if(current_colorAB == color2){
                  AB.setAttribute({fillColor:color1, highlightfillColor: color1});
                  current_colorAB = color1;
              }
              else{
                  AB.setAttribute({fillColor:color2, highlightfillColor: color2});
                  current_colorAB = color2;
              }
          }
      }

      function colorAC(posx, posy){
          if(insideA(posx, posy) == true && insideB(posx, posy) == false && insideC(posx, posy) == true){
              if(current_colorAC == color2){
                  AC.setAttribute({fillColor:color1, highlightfillColor: color1});
                  current_colorAC = color1;
              }
              else{
                  AC.setAttribute({fillColor:color2, highlightfillColor: color2});
                  current_colorAC = color2;
              }
          }
      }

      function colorBC(posx, posy){
          if(insideA(posx, posy) == false && insideB(posx, posy) == true && insideC(posx, posy) == true){
              if(current_colorBC == color2){
                  BC.setAttribute({fillColor:color1, highlightfillColor: color1});
                  current_colorBC = color1;
              }
              else{
                  BC.setAttribute({fillColor:color2, highlightfillColor: color2});
                  current_colorBC = color2;
              }
          }
      }

      function colorABC(posx, posy){
          if(insideA(posx, posy) == true && insideB(posx, posy) == true && insideC(posx, posy) == true){
              if(current_colorABC == color2){
                  ABC.setAttribute({fillColor:color1, highlightfillColor: color1});
                  current_colorABC = color1;
              }
              else{
                  ABC.setAttribute({fillColor:color2, highlightfillColor: color2});
                  current_colorABC = color2;
              }
          }
      }

      function colorUniverse(posx, posy){
          if(insideA(posx, posy) == false && insideB(posx, posy) == false && insideC(posx, posy) == false && posx <= wpol && posx >= -wpol && posy <= hpol && posy >= -hpol){
              if(current_colorUniverse == color2){
                  universe.setAttribute({fillColor:color1, highlightfillColor: color1});
                  current_colorUniverse = color1;
              }
              else{
                  universe.setAttribute({fillColor:color2, highlightfillColor: color2});
                  current_colorUniverse = color2;
              }
          }
      }


      graph.on('down', colorThings);

      //Images
      //var tap = graph.create('image', ['/assets/test.svg', [5,7.5], [size_tap, size_tap]], {visible: true, fixed: true});
      //Functions
      function whiteEverything(){
          if(current_colorA == color2){
              a1.setAttribute({fillColor:color1, highlightfillColor: color1});
              current_colorA = color1;
          }
          if(current_colorB == color2){
              a2.setAttribute({fillColor:color1, highlightfillColor: color1});
              current_colorB = color1;
          }
          if(current_colorC == color2){
              a3.setAttribute({fillColor:color1, highlightfillColor: color1});
              current_colorC = color1;
          }
          if(current_colorAB == color2){
              AB.setAttribute({fillColor:color1, highlightfillColor: color1});
              current_colorAB = color1;
          }
          if(current_colorAC == color2){
              AC.setAttribute({fillColor:color1, highlightfillColor: color1});
              current_colorAC = color1;
          }
          if(current_colorBC == color2){
              BC.setAttribute({fillColor:color1, highlightfillColor: color1});
              current_colorBC = color1;
          }
          if(current_colorABC == color2){
              ABC.setAttribute({fillColor:color1, highlightfillColor: color1});
              current_colorABC = color1;
          }
          if(current_colorUniverse == color2){
              universe.setAttribute({fillColor:color1, highlightfillColor: color1});
              current_colorUniverse = color1;
          }
      }

      function solution1(){
          if(
              current_colorA == color2 &&
              current_colorB == color2 &&
              current_colorC == color1 &&
              current_colorAB == color2 &&
              current_colorAC == color2 &&
              current_colorBC == color2 &&
              current_colorABC == color2 &&
              current_colorUniverse == color1
          ){
              return true;
          }
          else{
              return false;
          }
      }

      function solution2(){
          if(
              current_colorA == color1 &&
              current_colorB == color1 &&
              current_colorC == color1 &&
              current_colorAB == color1 &&
              current_colorAC == color1 &&
              current_colorBC == color1 &&
              current_colorABC == color2 &&
              current_colorUniverse == color1
          ){
              return true;
          }
          else{
              return false;
          }
      }

      function solution3(){
          if(
              current_colorA == color2 &&
              current_colorB == color2 &&
              current_colorC == color2 &&
              current_colorAB == color2 &&
              current_colorAC == color2 &&
              current_colorBC == color1 &&
              current_colorABC == color1 &&
              current_colorUniverse == color2
          ){
              return true;
          }
          else{
              return false;
          }
      }

      function solution4(){
          if(
              current_colorA == color2 &&
              current_colorB == color2 &&
              current_colorC == color1 &&
              current_colorAB == color2 &&
              current_colorAC == color1 &&
              current_colorBC == color2 &&
              current_colorABC == color1 &&
              current_colorUniverse == color1
          ){
              return true;
          }
          else{
              return false;
          }
      }

      function solution5(){
          if(
              current_colorA == color1 &&
              current_colorB == color1 &&
              current_colorC == color1 &&
              current_colorAB == color1 &&
              current_colorAC == color2 &&
              current_colorBC == color2 &&
              current_colorABC == color2 &&
              current_colorUniverse == color1
          ){
              return true;
          }
          else{
              return false;
          }
      }

     function shuffleit()
     {
       if(i == 0)
       {
           txt1.setText('A ⋂ B ⋂ C');
       }
       else if( i == 1)
       {
           txt1.setText('U - B ⋂ C')
       }
       else if( i == 2)
       {
           txt1.setText('A ⋃ B - C ⋂ A')
       }
       else if( i == 3)
       {
           txt1.setText('(A ⋂ C) ⋃ (B ⋂ C)')
       }
     }
shuffle.on('down', function(){
  shuffleit();
  if(i<=4)
  {
    if(i == 0 && solution1() == true)
    { //is correct
        cross[i].setAttribute({visible:false});
        exclaim[i].setAttribute({visible:false});
        check[i].setAttribute({visible:true});
        yourScore = yourScore+1;
    }
    else if(i == 1 && solution2() == true)
    { //is correct
        cross[i].setAttribute({visible:false});
        exclaim[i].setAttribute({visible:false});
        check[i].setAttribute({visible:true});
        yourScore = yourScore+1;
    }
    else if(i == 2 && solution3() == true)
    { //is correct
        cross[i].setAttribute({visible:false});
        exclaim[i].setAttribute({visible:false});
        check[i].setAttribute({visible:true});
        yourScore = yourScore+1;
    }
    else if(i == 3 && solution4() == true)
    { //is correct
        cross[i].setAttribute({visible:false});
        exclaim[i].setAttribute({visible:false});
        check[i].setAttribute({visible:true});
        yourScore = yourScore+1;
    }
    else if(i == 4 && solution5() == true)
    { //is correct
        cross[i].setAttribute({visible:false});
        exclaim[i].setAttribute({visible:false});
        check[i].setAttribute({visible:true});
        yourScore = yourScore+1;
    }
    else
    {
      cross[i].setAttribute({visible:true});
      yourWrong = yourWrong+1;
    }
  }
  if(i==4)
  {
  bck.setAttribute({opacity:1});
  scoreCard.setAttribute({visible:true});
  missedCard.setAttribute({visible:true});
  wrongCard.setAttribute({visible:true});
  redo.setAttribute({visible:true});
  }
  else
  {
    scoreCard.setAttribute({visible:false});
    missedCard.setAttribute({visible:false});
    wrongCard.setAttribute({visible:false});
    i=i+1;
  }
    hide[i].setAttribute({visible:false});
    whiteEverything();
});

      function hideOk(){
          ok1.setAttribute({visible: false});
          ok2.setAttribute({visible: false});
          cross1.setAttribute({visible: false});
          cross2.setAttribute({visible: false});
      }

      test.on('down', function()
      {
      //  note.setAttribute({visible:false});
          if(i == 0 && solution1() == true)
      	  { //is correct
              cross[i].setAttribute({visible:false});
              exclaim[i].setAttribute({visible:false});
              check[i].setAttribute({visible:true});
          }
          else if(i == 1 && solution2() == true)
      	  { //is correct
              cross[i].setAttribute({visible:false});
              exclaim[i].setAttribute({visible:false});
              check[i].setAttribute({visible:true});
          }
          else if(i == 2 && solution3() == true)
          { //is correct
              cross[i].setAttribute({visible:false});
              exclaim[i].setAttribute({visible:false});
              check[i].setAttribute({visible:true});
          }
          else if(i == 3 && solution4() == true)
          { //is correct
              cross[i].setAttribute({visible:false});
              exclaim[i].setAttribute({visible:false});
              check[i].setAttribute({visible:true});
          }
          else if(i == 4 && solution5() == true)
          { //is correct
              cross[i].setAttribute({visible:false});
              exclaim[i].setAttribute({visible:false});
              check[i].setAttribute({visible:true});
          }
          else
          {
            cross[i].setAttribute({visible:true});
          }
      });
      redo.on('down', function(){
      	//Hiding all blue circles
      	  hide.forEach(function(item){item.setAttribute({visible:true})});
          hide[0].setAttribute({visible:false});
        //Hiding all check marks
          check.forEach(function(item){item.setAttribute({visible:false})});
        //Hiding all cross marks //
          cross.forEach(function(item){item.setAttribute({visible:false})});
        //Hiding all exclaimation marks
      	  exclaim.forEach(function(item){item.setAttribute({visible:false})});
        ///////////////resetting scores//////////////////
          //note.setAttribute({visible:false});
      	  yourScore=0;
      	  yourMissed=0;
      	  yourWrong=0;
      	  yourTotal=0;
      	  scoreCard.setAttribute({visible:false});
      	  missedCard.setAttribute({visible:false});
          wrongCard.setAttribute({visible:false});
        /////////////////////////////////////////////
      	  i=0;
      	  bck.setAttribute({opacity:0});
      	  pointers.forEach(function(item){item.setAttribute({visible:false})});
          redo.setAttribute({visible:false});
          txt1.setText('A ⋃ B');
          ////////////////////////ALL CHANGES GO HERE/////////////
          //Ptm.moveTo([PtmList[0], 8], 200);
          ////////////////////////////////////////////////////////
          //alpha.setAttribute({visible:true});
          //beta.setAttribute({visible:true});
      });
      graph.unsuspendUpdate();
    },
}
export default Boxes;
